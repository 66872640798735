import axios from 'axios'
import Encrypt from "@/helpers/encrypt"
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'
import AppConfig from '@/configs/app.config.js'

axios.interceptors.request.use(async function(config) {
  const token = await Auth.getToken()
  config.withCredentials = true
  config.headers.Authorization = `Bearer ${token}`
  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error.response.data);
});

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if(error.response.data.status === 401 && !Swal.isVisible()) {
      localStorage.removeItem(AppConfig.key.LoggedIn)
      Swal.fire({
        title: 'กรุณาเข้าสู่ระบบใหม่',
        text: error?.response?.data?.message || '',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      .then(()=>{
        Auth.logout()
      })

      return Promise.reject({
        data: {
          hideAlert: true
        }
      })
    }else{
      return Promise.reject(error?.response?.data)
    }
  }
)

export default axios
